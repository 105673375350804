import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeadbotRoute } from "../../../services/Constants/HeadbotRoute";
import { PrimaryButtonLink } from "../../atoms/ButtonLink/PrimaryButtonLink";
import { WithAuth } from "../../molecules/Auth/WithAuth";
import { FullsizeImage } from "../../molecules/FullsizeImage/FullsizeImage";
import { ScrollablePageTemplate } from "../../templates/ScrollablePageTemplate/ScrollablePageTemplate";
import { Root } from "./ImageDetailsPage.styles";
import { SecondaryButtonLink } from "../../atoms/ButtonLink/SecondaryButtonLink";

interface IProps {
    readonly jobId: string;
    readonly imageId: string;
}

export const ImageDetailsPage: React.FC<React.PropsWithChildren<IProps>> = ({ imageId, jobId }) => {
    const [t] = useTranslation();
    const description = t("imageDetailsPage.description");
    const title = t("imageDetailsPage.title");
    return (
        <ScrollablePageTemplate title={title} description={description} canonical={HeadbotRoute.ViewJobImage(jobId, imageId)}>
            <WithAuth>
                <Root>
                    <FullsizeImage jobId={jobId} imageId={imageId} height={500} width={500} />
                    <PrimaryButtonLink to={HeadbotRoute.PurchasePrintPage(jobId, imageId)}>
                        {t("imageDetailsPage.buyPrint")}
                    </PrimaryButtonLink>
                    <SecondaryButtonLink to={HeadbotRoute.ManageJob(jobId)}>{t("imageDetailsPage.backToGallery")}</SecondaryButtonLink>
                </Root>
            </WithAuth>
        </ScrollablePageTemplate>
    );
};
